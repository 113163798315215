<template>
  <div class="ScientificResearchProcess">
    <div class="ScientificResearchProcess_left">
      <treedata @projectId-child="projectIdClick" @projectId-key="projectIdkey"></treedata>
    </div>
    <div class="ScientificResearchProcess_right" :class="{ ScientificResearchProcess_right1: b == true }">
      <div class="ScientificResearchProcess_right_left" :class="classshow()">
        <div class="ScientificResearchProcess_right_left_top" :class="{ ScientificResearchProcess_right_left_top1: show == true }">
          <h2>
            <p>
              <span></span>
              <span  class="xiangmuxqing">项目详情</span>
            </p>
            <div v-show="list.remainDay >= 0" style="float: right;margin-right: 26px;">
               <span class="scientific_text">距离结束还有</span>
               <span v-for="(item,index) in nums" :key="index" style="line-height:20px;text-align:center;display:inline-block;width: 20px;height:20px;background: #3388FF;color:white;opacity: 1;border-radius: 2px;font-size: 18px;margin-right: 2px">{{item}}</span>
                <span class="scientific_text">天</span>
            </div>
            <div v-show="list.remainDay < 0" style="float: right;margin-right: 26px;">
              <span class="scientific_text">已超过</span>
              <span v-for="(item,index) in nums" :key="index" style="text-align:center;line-height:20px;vertical-align:middle;color:white;display:inline-block;width: 20px;height: 20px;background: red;opacity: 1;border-radius: 2px;font-size: 18px;margin-right: 2px;">{{item}}</span>
              <span class="scientific_text">天</span>
            </div>
          </h2>
          <div class="ScientificResearchProcess_div" v-show="show == false">
            <div class="ScientificResearchProcess_div_left">
              <div class="ScientificResearchProcess_div_top">
                <p>
                  <img src="../../../assets/image/t1.png" /><span
                    >{{list.TopicName|ellipsis}}</span
                  ><span v-show="list.IsCheck == 1">{{list.CheckText}}</span>
                </p>
                <p>
                  <img src="../../../assets/image/t2.png" /><span
                    >负责人:&nbsp;{{ list.Principal }}</span
                  ><img src="../../../assets/image/t2.png" /><span
                    >参研人员:
                  <a v-for="(item,index) in arr" :key="index">
                    <a>{{item.UserName}}</a>
                    <a v-show="index != arr.length-1">,</a>
                   </a>
                </span
                  >
                </p>
                <p>
                  <img src="../../../assets/image/t3.png" /><span
                    >起始时间:&nbsp;{{list.StartDate}}~{{list.EndDate}}</span
                  >
                </p>
              </div>
              <div class="ScientificResearchProcess_div_bottom">
                <el-steps
                  :space="200"
                  :active="active"
                  finish-status="success"
                  :align-center="true"
                >
                <el-step
                    style="cursor:pointer;"
                    v-for="(item, index) in Stepsarr"
                    :key="index"
                    @click.native="clickstep(item,index)">
                   <template slot="description">
                          <div class="div1" :class="{div2:index==isnum}">{{item.FlowName}}</div>
                    </template>
                    </el-step>
                </el-steps>
              </div>
            </div>
            <div class="ScientificResearchProcess_div_right">
            <h2 class="mainC">
            <el-progress
              type="circle"
              :percentage="percentage"
              :stroke-width="18"
              :width="200"
            ></el-progress>
            <p>项目进度</p>
          </h2>
            </div>
          </div>
        </div>
        <div
          class="ScientificResearchProcess_right_left_bottom"
          :class="{
            ScientificResearchProcess_right_left_bottom1: show == true,
          }"
        >
          <div class="ScientificResearchProcess_head">
            <button type="primary">{{arrtiele}}</button>
            <span v-show="reject !=null">
              <el-tooltip class="item" effect="dark" :content="reject" placement="top-start">
                 <span v-show="StagestatusShow==1?true:false">驳回原因:{{reject}}</span>
              </el-tooltip>
            </span>
<!--     <el-tooltip class="item" effect="dark" :content="reject" placement="top-start">-->
<!--       <span v-show="StagestatusShow==1?true:false">驳回原因:{{reject}}</span>-->
<!--    </el-tooltip>-->
            </div>
          <div class="ScientificResearchProcess_box">
            <div class="ScientificResearchProcess_box_left">
              <h2>
                <p>
                  <img src="../../../assets/image/mulu.png" />
                  <span>目录列表</span>
                </p>
                <p v-show="disabledflag==true?false:true">
                  <img src="../../../assets/image/xiangmu/add.png" @click="Newdirectory"  v-show="StagestatusShow==3?false:true"/>
                  <img src="../../../assets/image/xiangmu/edit.png" @click="Modifydirectory" v-show="StagestatusShow==3?false:true"/>
                  <img src="../../../assets/image/xiangmu/del.png" @click="Deletedirectory" v-show="StagestatusShow==3?false:true"/>
                  <img src="../../../assets/image/xiangmu/daochu.png" @click="Directoryupload" v-show="StagestatusShow==3?false:true"/>
                </p>
              </h2>
              <div class="ScientificResearchProcess_mulu">
                <div
                  v-for="(item, index) in Directoryarray"
                  :key="index"
                  class="ScientificResearchProcess_mulu_1"
                >
                  <h3>
                    <span class="span3">
                      {{ item.FlowFolder.FolderName }}
                        <el-tooltip class="item" effect="light" placement="right" popper-class="andclass">
                                          <div slot="content">
                                            <p class="p1" style="width: 500px">
                                             {{item.FlowFolder.Remarks}}
                                            </p>
                                          </div>
                            <img src="../../../assets/image/mulushuoming.png"/>
                        </el-tooltip>
                        <span class="spana" v-show="item.DataList.length==0?false:item.DataList[0].CheckFlag==-1&&list.CheckText=='驳回'?true:false" @click="OneclickFinish(item.FlowFolder.Id,item.FlowFolder.DataGuid)" style="cursor:pointer;">完成提交</span>
                    </span>
                        <span class="span1" v-show="oppp()">{{item.DataList.length==0?"待审核":item.DataList[0].DataCheckText}}</span>
                        <span v-show="StagestatusShow==2" class="span2">
                        <el-dropdown>
                          <span class="el-dropdown-link" style="display:inline-block;margin-bottom: 10px;vertical-align: middle">
                              <i class="el-icon-arrow-down el-icon--right"></i>{{item.DataList.length==0?"待审核":item.DataList[0].DataCheckText}}
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="Documentrejection(item.FlowFolder.Id,item.FlowFolder.DataGuid)">审核驳回</el-dropdown-item>
                            <el-dropdown-item @click.native="Documentadoption(item.FlowFolder.Id,item.FlowFolder.DataGuid)">审核通过</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                        </span>
                  </h3>
                  <h5 v-for="(row, i) in item.DataList" :key="i">
                    <img src="../../../assets/image/xiangmu/wenjian.png" style="width: 20px;height: 20px"/>
                                <el-tooltip placement="top" effect="light" popper-class="my-tooltip">
                                    <div slot="content" v-show="StagestatusShow==3||disabledflag==true?false:true" >
                                            <img src="../../../assets/image/g.png" style="margin-right:20px;" @click="modifyfileexplain(row)"/>
                                            <img src="../../../assets/image/s1.png" style="margin-right:20px;" @click="Deletecatalogfile(row.Id)"/>
                                            <img src="../../../assets/image/x.png" @click="cataloguedownloadfile(row.Id)"/>
                                    </div>
                                    <span>{{ row.FileName }}.{{ row.Suffix }}</span>
                                </el-tooltip>
                    <p v-show="row.Remarks==''?false:true">
                      文件说明:{{row.Remarks}}
                    </p>
                    <p v-show="row.DisRemarks==null||row.DisRemarks==''?false:true">
                      驳回原因:{{row.DisRemarks}}
                    </p>
                  </h5>
                </div>
              </div>
            </div>
            <div class="ScientificResearchProcess_box_right">
               <h2>
                <p>
                  <img src="../../../assets/image/taskmanagement/shisijih.png" />
                  <span>实施计划</span>
                </p>
                <p v-show="disabledflag==true?false:true">
                  <img src="../../../assets/image/xiangmu/add.png" @click="Addplan" v-show="StagestatusShow==3?false:true"/>
                  <img src="../../../assets/image/xiangmu/edit.png" @click="Modifyplan" v-show="StagestatusShow==3?false:true"/>
                  <img src="../../../assets/image/xiangmu/del.png" @click="Deleteschedule" v-show="StagestatusShow==3?false:true"/>
                  <img src="../../../assets/image/xiangmu/daochu.png" @click="Planfileupload" v-show="StagestatusShow==3?false:true"/>
                </p>
              </h2>
              <div class="ScientificResearchProcess_box_jihua">
                <div class="ScientificResearchProcess_box_mm">
                    <el-timeline>
                        <el-timeline-item v-for="(item,index) in planarray" :key="index">
                            <div class="timeline_div">
                                   <div class="timeline_h6">
                                     <span class="span11">{{item.FlowPlan.PlanName}}
                                      <el-tooltip class="item" effect="light" :content="item.FlowPlan.Remarks" placement="right">
                                        <img src="../../../assets/image/mulushuoming.png" style="vertical-align: middle;"/>
                                    </el-tooltip>
                                    </span>
                                     <!-- <span class="span22" v-show="list.IsCheck!=1">已完成</span> -->
                                     <span v-show="StagestatusShow==2" class="span22">{{item.FlowPlan.FinishFlag == 0 ? "未完成" : "已完成"}}</span>
                                     <span v-show="oppp()" class="span33">
                                      <el-dropdown>
                                          <span class="span1">
                                              <i class="el-icon-arrow-down el-icon--right"></i>{{item.FlowPlan.FinishFlag == 0 ? "未完成" : "已完成"}}
                                          </span>
                                          <el-dropdown-menu slot="dropdown">
                                          <el-dropdown-item  @click.native="hangintheair(item.FlowPlan.Id,item.FlowPlan)">未完成</el-dropdown-item>
                                          <el-dropdown-item @click.native="Completed(item.FlowPlan.Id,item.FlowPlan)">已完成</el-dropdown-item>
                                          </el-dropdown-menu>
                                      </el-dropdown>
                                     </span>
                                   </div>
                                    <div class="timeline_h3">
                                     <img src="../../../assets/image/t3.png">
                                     <span>计划起始时间:{{item.FlowPlan.StartDate==null?"":item.FlowPlan.StartDate.substr(0,10)}}~{{item.FlowPlan.EndDate==null?"":item.FlowPlan.EndDate.substr(0,10)}}</span>
                                   </div>
                                    <div class="timeline_h4" v-for="(row,i) in item.DataList" :key="i">
                                     <img src="../../../assets/image/xiangmu/wenjian.png">
                                    <el-tooltip placement="top" effect="light" popper-class="my-tooltip">
                                        <div slot="content" v-show="StagestatusShow==3||disabledflag==true?false:true">
                                                <img src="../../../assets/image/g.png" style="margin-right:20px;" @click="Modifyplandescription(row)"/>
                                                <img src="../../../assets/image/s1.png" style="margin-right:20px;" @click="Deleteschedulefile(row.Id)"/>
                                                <img src="../../../assets/image/x.png" @click="plandownloadfile(row.Id)"/>
                                        </div>
                                        <span>{{ row.FileName }}.{{ row.Suffix }}</span>
                                    </el-tooltip>
                                      <!-- <span v-show="StagestatusShow==1">{{row.DataCheckText}}</span> -->
                                      <!-- <span v-show="StagestatusShow==2">
                                      <el-dropdown>
                                        <span class="el-dropdown-link">
                                            <i class="el-icon-arrow-down el-icon--right"></i>{{row.DataCheckText}}
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                          <el-dropdown-item  @click.native="Planrejection(item.FlowPlan.Id,row.Id)">审核驳回</el-dropdown-item>
                                          <el-dropdown-item @click.native="Planpassed(item.FlowPlan.Id,row.Id)">审核通过</el-dropdown-item>
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                      </span> -->
                                      <p v-show="row.Remarks==''?false:true">文件说明:{{row.Remarks}}</p>
                                       <p v-show="row.DisRemarks==null||row.DisRemarks==''?false:true">驳回原因:{{row.DisRemarks}}</p>
                                   </div>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                </div>
              </div>
            </div>
          </div>
          <div class="ScientificResearchProcess_box_center">
            <span style="margin-right: 10px;" v-show="list.IsCheck==1">
            <el-button type="primary" icon="el-icon-user" @click="Submitforreview" v-show="StagestatusShow==1">提交审核</el-button>
            <el-button type="primary" icon="el-icon-check" @click="Approved" v-show="StagestatusShow==2">审核通过</el-button>
            <el-button type="primary" icon="el-icon-close" @click="Stagerejection" v-show="StagestatusShow==2">审核驳回</el-button>
            </span>
            <el-button type="primary" icon="el-icon-unlock" v-show="StagestatusShow==3||list.LockFlag==1?false:true" @click="Coursenotlocked(0)">课题未锁定</el-button>
            <el-button type="primary" icon="el-icon-lock" v-show="StagestatusShow==3||list.LockFlag==0?false:true" @click="Coursenotlocked(1)">课题已锁定</el-button>
          </div>
        </div>
      </div>
      <div class="ScientificResearchProcess_right_right" :class="{ScientificResearchProcess_right_right1:isshow== true}">
          <h2>
             <span></span>
             <span>操作记录</span>
          </h2>
          <div class="ScientificResearchProcess_right_p">
      <el-tooltip v-for="(item,index) in Operationrecordlist" :key="index" placement="top">
            <div slot="content" class="div1">
              <p class="p1">{{item.CreateDate}}{{item.UserName}}{{item.LogText}}</p>
            </div>
            <div class="div2">
              <span class="el-icon-user-solid" style="color:#999"></span>
              <span>{{item.CreateDate}}</span>
              <span>{{item.UserName}},</span>
              <span>{{item.LogText}},</span>
            </div>
          </el-tooltip>
          </div>
      </div>
    </div>
    <div style="position: fixed;top:50%;right: 0">
      <img src="../../../assets/image/guan.png" @click="close" v-show="isshow" style="width: 30px;height: 30px"/>
      <img src="../../../assets/image/kai.png" @click="open" v-show="!isshow" style="width: 30px;height: 30px"/>
    </div>
<!--    科研过程提示-->
    <guidance-chart :tips="tips" @tips-child="tipsChild"></guidance-chart>
    <!------------------------------------ 目录弹框 -------------------------------------------------------------------------->
    <div class="addcataloguedialog"><!----新增目录------->
      <el-dialog :visible.sync="addcataloguedialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">新增目录</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="目录名称:">
              <el-input v-model="FolderName"></el-input>
            </el-form-item>
            <el-form-item label="目录说明:">
              <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="addcataloguedialog = false">取消</el-button>
          <el-button @click="Confirmnewdirectory" :loading="loadingfalg">确定新增</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addcataloguedialog"><!----修改目录------->
      <el-dialog :visible.sync="Modifydirectorydialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">修改目录</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="选择目录:">
                <el-select placeholder="请选择" v-model="Id">
                    <el-option :label="item.FlowFolder.FolderName" :value="item.FlowFolder.Id" v-for="(item,index) in Directoryarray" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="目录名称:">
              <el-input v-model="FolderName"></el-input>
            </el-form-item>
            <el-form-item label="目录说明:">
              <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="Modifydirectorydialog = false">取消</el-button>
          <el-button @click="Confirmtomodifythedirectory">确定修改</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addcataloguedialog"><!----删除目录------->
      <el-dialog :visible.sync="Deletedirectorydialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">删除目录</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="选择目录:">
                <el-select placeholder="请选择" v-model="Id">
                    <el-option :label="item.FlowFolder.FolderName" :value="item.FlowFolder.Id" v-for="(item,index) in Directoryarray" :key="index"></el-option>
                </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="Deletedirectorydialog = false">取消</el-button>
          <el-button @click="determineDeletedirectory">确定删除</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addcataloguedialog"><!----目录文件上传------->
      <el-dialog :visible.sync="Directoryuploaddialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">目录文件上传</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="选择目录:">
                <el-select placeholder="请选择" v-model="Id">
                    <el-option :label="item.FlowFolder.FolderName" :value="item.FlowFolder.Id" v-for="(item,index) in Directoryarray" :key="index"></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="文件类型:">
                <el-select placeholder="请选择" v-model="Type">
                    <el-option :label="item.name" :value="item.val" v-for="(item,index) in listoption" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="相关文件:">
                        <el-upload
                        class="upload-demo"
                        ref="cataloguenewupload"
                        accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc"
                        multiple
                        action="#"
                        :file-list="cataloguefileList"
                        :auto-upload="false"
                        :on-change="oncheng"
                    >
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
            </el-form-item>
            <el-form-item label="文件说明:">
              <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="Directoryuploaddialog = false">取消</el-button>
          <el-button type="primary" :loading="loadingfalg" @click="OKtoupload">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addcataloguedialog"><!----修改目录文件说明------->
      <el-dialog :visible.sync="modifyfileexplaindialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">修改目录文件说明</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="选择目录:">
              <el-select placeholder="请选择" v-model="editFolderPId">
                <el-option :label="item.FlowFolder.FolderName" :value="item.FlowFolder.DataGuid" v-for="(item,index) in Directoryarray" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文件类型:">
              <el-select placeholder="请选择" v-model="editType">
                <el-option :label="item.name" :value="item.val" v-for="(item,index) in listoption" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="相关文件:">
              <span>{{editFileName}}</span>
            </el-form-item>
            <el-form-item label="文件说明:">
                   <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="modifyfileexplaindialog = false">取消</el-button>
          <el-button @click="OkDocumentdescription">确定修改</el-button>
        </div>
      </el-dialog>
    </div>
     <div class="addcataloguedialog"><!----删除目录文件------->
      <el-dialog :visible.sync="Deletecatalogfiledialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">删除目录文件</span>
        </div>
        <div class="body">
              <p><span class="span1">!</span><span class="span2">此操作将永久删除该文件,是否继续？</span></p>
        </div>
        <div class="btn">
          <el-button @click="Deletecatalogfiledialog = false">取消</el-button>
          <el-button @click="OkDeletefile">确定删除</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addcataloguedialog"><!----目录文件审核驳回------->
      <el-dialog :visible.sync="Catalogfilerejecteddialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">目录审核驳</span>
        </div>
        <div class="body">
           <p><span class="span1">!</span><span class="span2">您确定驳回吗？</span></p>
          <!-- <el-form label-width="100px">
            <el-form-item label="驳回说明:">
                   <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
          </el-form> -->
        </div>
        <div class="btn">
          <el-button @click="Catalogfilerejecteddialog = false">取消</el-button>
          <el-button @click="OKreject">确定驳回</el-button>
        </div>
      </el-dialog>
    </div>
    <!------------------------------------ 计划弹框 -------------------------------------------------------------------------->
    <div class="addcataloguedialog"><!----新增计划------->
      <el-dialog :visible.sync="Newplandialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">创建计划</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="计划名称:">
              <el-input v-model="PlanName"></el-input>
          <!-- <el-autocomplete
                  class="inline-input"
                  v-model="HospitalName"
                  value-key="HospitalName"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                ></el-autocomplete> -->
            </el-form-item>
            <el-form-item label="计划说明:">
              <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
            <el-form-item label="计划时间:">
                  <el-date-picker
                    v-model="timelist"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="Newplandialog = false">取消</el-button>
          <el-button @click="OKCreateplan" :loading="loadingfalg">确定创建</el-button>
        </div>
      </el-dialog>
    </div>
     <div class="addcataloguedialog"><!----修改计划------->
      <el-dialog :visible.sync="Modifyplandialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">修改计划</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="选择计划:">
                <el-select placeholder="请选择" v-model="Id">
                    <el-option :label="item.FlowPlan.PlanName" :value="item.FlowPlan.Id" v-for="(item,index) in planarray" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="计划名称:">
              <el-input v-model="PlanName"></el-input>
            </el-form-item>
            <el-form-item label="计划说明:">
              <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
            <el-form-item label="计划时间:">
                  <el-date-picker
                    v-model="timelist"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="Modifyplandialog = false">取消</el-button>
          <el-button @click="OKModifyplan">确定修改</el-button>
        </div>
      </el-dialog>
    </div>
     <div class="addcataloguedialog"><!----删除计划------->
      <el-dialog :visible.sync="Deletescheduledialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">删除计划</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="选择计划:">
                <el-select placeholder="请选择" v-model="Id">
                    <el-option :label="item.FlowPlan.PlanName" :value="item.FlowPlan.Id" v-for="(item,index) in planarray" :key="index"></el-option>
                </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="Deletescheduledialog = false">取消</el-button>
          <el-button @click="OkDeleteschedule">确定删除</el-button>
        </div>
      </el-dialog>
    </div>
     <div class="addcataloguedialog"><!----计划文件上传------->
      <el-dialog :visible.sync="Planfileuploaddialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">计划文件上传</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="选择计划:">
                <el-select placeholder="请选择" v-model="Id">
                    <el-option :label="item.FlowPlan.PlanName" :value="item.FlowPlan.Id" v-for="(item,index) in planarray" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="文件类型:">
                <el-select placeholder="请选择" v-model="Type">
                    <el-option :label="item.name" :value="item.val" v-for="(item,index) in listoption" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="相关文件:">
                        <el-upload
                        class="upload-demo"
                        ref="plannewupload"
                        accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc"
                        multiple
                        action="#"
                        :file-list="planfileList"
                        :auto-upload="false"
                        :on-change="oncheng"
                    >
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
            </el-form-item>
            <el-form-item label="文件说明:">
              <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="Planfileuploaddialog = false">取消</el-button>
          <el-button type="primary" :loading="loadingfalg" @click="OKPlanupload">确定</el-button>
        </div>
      </el-dialog>
    </div>
     <div class="addcataloguedialog"><!----修改计划文件说明------->
      <el-dialog :visible.sync="Modifyplandescriptiondialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">修改计划文件说明</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="选择计划:">
              <el-select placeholder="请选择" v-model="editFolderPId">
                <el-option :label="item.FlowPlan.PlanName" :value="item.FlowPlan.DataGuid" v-for="(item,index) in planarray" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文件类型:">
              <el-select placeholder="请选择" v-model="editType">
                <el-option :label="item.name" :value="item.val" v-for="(item,index) in listoption" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="相关文件:">
              <span>{{editFileName}}</span>
            </el-form-item>
            <el-form-item label="文件说明:">
              <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="Modifyplandescriptiondialog = false">取消</el-button>
          <el-button @click="OKModifyplandescription">确定修改</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addcataloguedialog"><!----删除计划文件------->
      <el-dialog :visible.sync="Deleteschedulefiledialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">删除目录文件</span>
        </div>
        <div class="body">
              <p><span class="span1">!</span><span class="span2">此操作将永久删除该文件,是否继续？</span></p>
        </div>
        <div class="btn">
          <el-button @click="Deleteschedulefiledialog = false">取消</el-button>
          <el-button @click="OKDeleteschedule">确定删除</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addcataloguedialog"><!----计划文件审核驳回------->
      <el-dialog :visible.sync="Plandocumentrejectiondialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">计划审核驳回说明</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="驳回说明:">
                   <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="Plandocumentrejectiondialog = false">取消</el-button>
          <el-button @click="OKPlandocumentrejection">确定驳回</el-button>
        </div>
      </el-dialog>
    </div>
    <!------------------------------------ 审核弹框 -------------------------------------------------------------------------->
    <div class="addcataloguedialog"><!----审核通过------->
      <el-dialog :visible.sync="allApproveddialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div class="body">
              <p><span class="span1">!</span><span class="span2">确定审核通过吗？</span></p>
        </div>
        <div class="btn">
          <el-button @click="allApproveddialog = false">取消</el-button>
          <el-button @click="OKallApproved">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addcataloguedialog"><!----目录文件审核驳回------->
      <el-dialog :visible.sync="Stageapprovalrejectiondialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">该阶段审核驳回说明</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="驳回说明:">
                   <el-input type="textarea" v-model="Remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="Stageapprovalrejectiondialog = false">取消</el-button>
          <el-button @click="OKStagerejection">确定驳回</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addcataloguedialog"><!----审核通过------->
      <el-dialog :visible.sync="lockingdialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div class="body">
              <p><span class="span1">!</span><span class="span2">您确定{{i}}课题吗？</span></p>
        </div>
        <div class="btn">
          <el-button @click="lockingdialog = false">取消</el-button>
          <el-button @click="OKlocking">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import treedata from "../../../components/treedata.vue";
import { mapState } from "vuex";
import api from "../../../api/url.js";
export default {
  data() {
    return {
      i:"解锁",
      editFileName: "",
      editType: "",
      editFolderPId: "",
      tips: {
        name: "scientific",
        url: require('../../../assets/image/tips/scientific.png')
      },
      isnum:0,
      isshow: false, // 右侧操作记录的张开
      nums: [], // 天数数组
      title:"项目",
      listoption:[{name:"成果文件",val:2},{name:"任务文件",val:3}],
      StagestatusShow:0,
      timelist:[],
      show: false,
      Operationrecordlist:[],
      Stepsarr: [], //阶段步骤条数组
      active: 0,
      Directoryarray: [], //目录数组
      planarray:[],//计划数组
      stageId: "", //阶段Id
      addcataloguedialog: false, //新增目录弹框
      Modifydirectorydialog:false,//修改目录弹框
      Deletedirectorydialog:false,//删除目录弹框
      Directoryuploaddialog:false,//目录文件上传弹框
      modifyfileexplaindialog:false,//目录修改文件说明弹框
      Deletecatalogfiledialog:false,//删除目录相关文件
      Newplandialog:false,//打开新增计划弹框
      Modifyplandialog:false,//打开修改计划弹框
      Deletescheduledialog:false,//打开删除计划弹框
      Planfileuploaddialog:false,//打开文件上传
      Modifyplandescriptiondialog:false,//打开计划修改文件说明弹框
      Deleteschedulefiledialog:false,//打开计划删除文件说明弹框
      Catalogfilerejecteddialog:false,//打开目录文件审核驳回弹框
      allApproveddialog:false,//审核通过
      Plandocumentrejectiondialog:false,//打开计划文件审核驳回弹框
      Stageapprovalrejectiondialog:false,//阶段审核驳回
      lockingdialog:false,//锁定弹框
      FolderName:"",//目录名称
      PlanName:"",//计划名称
      Remarks:"",//目录说明
      Id:"",//储存文件的id
      ParentID:"",//储存文件的上级id
      cataloguefileList:[],//目录储存文件
      planfileList:[],//计划储存文件
      list:[],//单个项目数据
      arr:[],//项目下的成员
      percentage:0,//百分比
      Type:"",
      reject:"",//阶段驳回原因
      cataloguearr:"",//目录
      planarr:"",//计划
      arrtiele:"",
      loadingfalg:false,
      filearr:[]
    };
  },
   filters: {
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 22) {
        return value.slice(0,22) + '...'
      }
      return value
    }
  },
  computed: mapState(["b","projectId","disabledflag"]),
  components: {
    treedata,
  },
  mounted(){
    this.Stepslist();
    this.singletopiclist()
    this.jurisdictionlist()
    this.Stageslist()
  },
  created() {
    // this.Stepslist();
    // this.singletopiclist()
    // this.jurisdictionlist()
    // this.Stageslist()
  },
  methods: {
   oncheng(file, fileList) {
      this.filearr = fileList;
      console.log(file, fileList);
    },
    tipsChild(data) {
      localStorage.setItem(this.tips.name,data)
    },
    oppp(){
      if(this.list.IsCheck==0){
        return false
      }else{
        if(this.StagestatusShow==1){
           return true
        }else{
          return false
        }
      }
    },
    classshow(){
      if(this.isshow==true&&this.b==true){
         return "ScientificResearchProcess_right_left3"
      }
        if(this.b==true){
           return "ScientificResearchProcess_right_left1"
        }
        if(this.isshow==true){
          return "ScientificResearchProcess_right_left2"
        }
    },
    close() {//关
      this.isshow = false;
    },
    open(){//开
      this.isshow = true;
    },
    projectIdkey(data){
      if(data==1){
        this.title="项目"
      }
      if(data==2){
        this.title="课题"
      }
      if(data==3){
        this.title="子课题"
      }
    },
    fn(id){
           api.catalogData(id).then(res => {
                //返回阶段所有目录信息
                if (res.data.Status == 1) {
                  this.Directoryarray = res.data.ExtraData;
                } else {
                  alert(res.data.Message);
                }
                console.log(res,"所有目录信息");
              });
           api.allplanData(id).then(res => {
                  //返回阶段所有计划信息
                  if (res.data.Status == 1) {
                    this.planarray = res.data.ExtraData;
                    console.log(res,"所有计划信息");
                  }else{
                    alert(res.data.Message);
                  }
                });
    },
    clickstep(item,i){
       //IsCheck==1是必须审核才能走下面的阶段，IsCheck==0是可以不通过审核直接走下面阶段
       if(this.list.IsCheck==1&&item.CheckFlag!=0){//
          //  alert("请先完成当前阶段")
            this.arrtiele=item.FlowName
            this.fn(item.Id)
            console.log(222);
       }
       if(this.list.IsCheck!=1){
         this.stageId=item.Id
         this.arrtiele=item.FlowName
         this.fn(item.Id)
         this.isnum=i
         console.log(333);
       }
       if(this.list.IsCheck==1&&item.CheckFlag==0){
            alert("请先完成当前阶段")
       }
       console.log(item);
    },
    remainDayList(n) { // 将天数切割成数组
      var str = n + "";
      var arr = [];
      str.split("").forEach(item => {
        arr.push(parseInt(item))
      })
      return arr
    },
    singletopiclist(){//单个项目
        let params=this.projectId==""?sessionStorage.getItem("projectId"):this.projectId//项目id
        api.singletopicData(params).then(res=>{
           console.log(res,"单个项目");
           if(res.data.Status==1){
             this.list=res.data.ExtraData
             var result = res.data.ExtraData.remainDay
             if (result>=0) {
               this.nums = this.remainDayList(result) // 天数数组
             } else {
               result = 0 - result
               this.nums = this.remainDayList(result) // 负数天数
             }
           this.list["StartDate"]=res.data.ExtraData.StartDate.substr(0,10)
           this.list["EndDate"]=res.data.ExtraData.EndDate.substr(0,10)
           }else{
             alert(res.data.Message)
           }
        })
    },
    jurisdictionlist(){//项目成员
        let params=this.projectId==""?sessionStorage.getItem("projectId"):this.projectId//项目id
        api.jurisdictionData(params).then(res=>{
           console.log(res,"项目成员");
           if(res.data.Status==1){
             this.arr=res.data.ExtraData
           }else{
             alert(res.data.Message)
           }
        })
    },
    Stageslist(){
        let params={
          TopicId:this.projectId==""?sessionStorage.getItem("projectId"):this.projectId,//项目id
          PageIndex:0,
          PageSize:20,
        }
        api.StagesData(params).then(res=>{
              if(res.data.Status==1){
                 this.Operationrecordlist=res.data.Entity
              }else{
                alert(res.data.Message)
              }
            console.log(res,"操作记录");
        })
    },
    downLoadXls(data, filename) {
      var blob = new Blob([data], { type: "application/vnd.ms-excel" }); //接收的是blob，若接收的是文件流，需要转化一下
      if (typeof window.chrome !== "undefined") {
        // Chrome version
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(data);
        link.download = filename;
        link.click();
      } else if (typeof window.navigator.msSaveBlob !== "undefined") {
        // IE version
        var blobs = new Blob([data], { type: "application/force-download" });
        window.navigator.msSaveBlob(blobs, filename);
      } else {
        // Firefox version
        var file = new File([data], filename, {
          type: "application/force-download",
        });
        // link.href="http://192.168.54.119/debug/api/TopicData/DownloadDataFile"
        // alert(URL.createObjectURL(file),"这是给啥");
        window.open(URL.createObjectURL(file));
      }
    },
    projectIdClick(data) { //树型结构传递过来的课题id
      console.log(data);
      this.$store.commit('SaveId',data)
      this.Stepslist()
      // this.Stepslistphp()
      this.singletopiclist()
      this.jurisdictionlist()
    },
    add() {
      this.show = true;
    },
   Stepslistphp() {//步骤条接口
      let params=this.projectId==""?sessionStorage.getItem("projectId"):this.projectId//项目id
      api.StepsData(params).then((res) => {
        //CheckFlag -1=驳回,0未开始，1=待审核，2=审核中,3=审核完成
        if (res.data.Status == 1) {
          this.Stepsarr = res.data.ExtraData.map((item, index,arr) => {
            if (item.CheckFlag == 3) {
              this.active = index + 1;
              this.percentage=parseInt((100/arr.length)*(index+1))
              this.stageId=item.Id
              this.arrtiele=item.FlowName
            }
            return item;
          });
        } else {
          alert(res.data.Message);
          this.$router.push({name:"ProjectManagementPage"})
        }
        console.log(res,"阶段信息");
      });
    },
    Stepslist() {//步骤条接口
       var falg=0
      let params=this.projectId==""?sessionStorage.getItem("projectId"):this.projectId//项目id
      api.StepsData(params).then((res) => {
        //CheckFlag -1=驳回,0未开始，1=待审核，2=审核中,3=审核完成
        if (res.data.Status == 1) {
              this.percentage=0
              this.Stepsarr = res.data.ExtraData.map((item, index,arr) => {
            if (item.CheckFlag == 1 || item.CheckFlag == 2||item.CheckFlag==-1) {
                this.active = index
                this.isnum=index
                this.reject=item.DisRemarks
                this.stageId=item.Id//阶段id
                this.arrtiele=item.FlowName
                this.fn(item.Id)
              api.StagestatusData({Id:item.Id}).then(res=>{
                   if(res.data.Status==1){
                     console.log(res);
                       if(res.data.ExtraData==-1 ||res.data.ExtraData==0 ){
                         this.$message.error("您没有科研过程查看的权限")
                         this.$router.push({name: "ProjectInformation"})
                         console.log("没有权限")
                       }
                       if(res.data.ExtraData==1){
                            console.log("能提交");
                            this.StagestatusShow=res.data.ExtraData
                       }
                       if(res.data.ExtraData==2){
                            console.log("可以审核");
                            this.StagestatusShow=res.data.ExtraData
                       }
                       if(res.data.ExtraData==3){
                            console.log("只能查看");
                                this.$message({
                                  message: '此项目您只能查看!',
                                  type: 'success'
                                });
                            this.StagestatusShow=res.data.ExtraData
                       }
                   }else{
                     alert(res.data.Message)
                   }
                   console.log(res,"阶段状态");
              })
            }
            if (item.CheckFlag == 3) {
              this.active = index + 1;
              this.percentage=parseInt((100/arr.length)*(index+1))
              falg++
              if(falg==arr.length){
              var pop=arr.pop()
              this.stageId=pop.Id
              this.arrtiele=pop.FlowName
              }else{
              this.stageId=arr[index+1].Id
              this.arrtiele=arr[index+1].FlowName
              this.isnum=index+1
              }
            }
            return item;
          });
        } else {
          alert(res.data.Message);
          this.$router.push({name:"ProjectManagementPage"})
        }
      });
    },
    //-------------------------------------------目录列表接口-------------------------------------------------------------------
    Newdirectory(){//打开新增目录弹框
        this.addcataloguedialog=true
        this.FolderName=""
        this.Remarks=""
        this.Id=""
    },
    Confirmnewdirectory(){//确定新增目录
      //新增目录
      this.loadingfalg=true
      console.log(this.stageId);
      let params = {
        TopicFlowId: this.stageId,
        FolderName: this.FolderName,
        Remarks: this.Remarks,
      };
      api.addcatalogData(params).then(res=> {
          console.log(res);
        if (res.data.Status == 1) {
            this.loadingfalg=false
            this.addcataloguedialog=false
            if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
            this.$message({
                message: '新增成功',
                type: 'success'
            });
        }else{
          this.loadingfalg=false
          alert(res.data.Message);
        }
      });
    },
    Modifydirectory(){//打开修改目录弹框
        this.Modifydirectorydialog=true
        this.FolderName=""
        this.Remarks=""
        this.Id=""
    },
    Confirmtomodifythedirectory(){//确定修改新增目录
    console.log(this.Id);
    let params = {
        Id: this.Id,
        FolderName: this.FolderName,
        Remarks: this.Remarks,
      };
      api.modifycatalogData(params).then(res => {
          console.log(res);
        if (res.data.Status == 1) {
            if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
             this.Modifydirectorydialog=false
             this.$message({
                message: '修改成功',
                type: 'success'
            });
        }else{
          alert(res.data.Message);
        }
      });
    },
    Deletedirectory(){//打开删除目录弹框
        this.Deletedirectorydialog=true
        this.FolderName=""
        this.Remarks=""
        this.Id=""
    },
    determineDeletedirectory(){//确定删除目录
        let params={
            Id:this.Id
        }
        api.deletecatalogData(params).then(res=>{
            console.log(res);
            if(res.data.Status==1){
                this.Deletedirectorydialog=false
            if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
                this.$message({
                message: '删除成功',
                type: 'success'
                });
            }else{
                alert(res.data.Message)
            }
        })
    },
    Directoryupload(){//打开目录文件上传
        this.Directoryuploaddialog=true
        this.FolderName=""
        this.Remarks=""
        this.Id=""
        this.cataloguefileList=[]
    },
    OKtoupload(){//确定上传目录
      this.loadingfalg=true
      let params = {
        Id: this.Id,
        Remarks: this.Remarks,
        UserId: sessionStorage.getItem("Id"),
        Type:this.Type
      };
      let fd = new FormData();
       this.filearr.forEach(file => {
            fd.append('file',file.raw)
      })
      fd.append("data", JSON.stringify(params));
      api.catalogUploadData(fd).then(res => {
        if (res.data.Status == 1) {
            if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
            this.loadingfalg=false
            this.Directoryuploaddialog=false
            this.$message({
                message: '上传成功',
                type: 'success'
            });
        }else{
          this.loadingfalg=false
          alert(res.data.Message);
        }
        console.log(res);
      });
      return true;
    },
    modifyfileexplain(row){//打开目录修改文件说明弹框
       this.modifyfileexplaindialog=true
       this.Remarks= row.Remarks
       this.Id=""
       this.Id=row.Id
       this.editFolderPId = row.PDataGuid
       this.editType = row.Type
       this.editFileName = row.FileName + "." + row.Suffix
       console.log(row,'编辑的数据')
    },
    OkDocumentdescription(){//确定修目录改文件说明
     let params = {
        Id: this.Id,
        CheckFlag: 1,
        Remarks: this.Remarks,
        Type: this.editType,
        PDataGuid: this.editFolderPId
      };
      api.modificationData(params).then(res => {
        if (res.data.Status == 1) {
             this.modifyfileexplaindialog=false
            if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
             this.$message({
                message: '修改成功',
                type: 'success'
            });
        }else{
           alert(res.data.Message)
        }
      });
    },
    Deletecatalogfile(Id){//打开目录删除文件弹框
        this.Deletecatalogfiledialog=true
        this.Id=""
        this.Id=Id
    },
    OkDeletefile(){//确定目录删除文件
        let params = {
        Id: this.Id,
      };
       api.DeletecatalogfileData(params).then(res=>{
            if(res.data.Status==1){
                this.Deletecatalogfiledialog=false
              if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
                  this.$message({
                    message: '删除成功',
                    type: 'success'
                });
            }else{
               alert(res.data.Message)
            }
       })
    },
    cataloguedownloadfile(Id){//目录文件下载
       api.filepermissionsData(Id).then(res=>{
         console.log(res);
         if(res.status==200){
                let params={
                  Id:Id
                }
               api.filedownloadData(params).then(res=>{
                  console.log(res,"22222222222");
                    let data = res.data;
                    let filename = decodeURIComponent(res.headers.filename);
                    this.downLoadXls(data, filename);
               })
         }else{
           alert(res.data.Message)
         }
       })
    },
    Documentrejection(Id,DataGuid){//文件审核驳回弹框
      this.Catalogfilerejecteddialog=true
      this.ParentID=""
      this.Id=""
      this.Remarks=""
      this.ParentID=Id
      this.Id=DataGuid
    },
    OKreject(){//确定文件审核驳回
    // console.log(this.Id);
      let params = {
        DataGuid:this.Id,
        Flag:-1,
        Remarks:this.Remarks,
      };
      api.filemodifyData(params).then(res=>{
        if(res.data.Status==1){
          console.log(222);
           this.Catalogfilerejecteddialog=false
               this.$message({
                    message: '审核驳回',
                    type: 'success'
                });
            if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
        }else{
          alert(res.data.Message)
        }
        console.log(res);
      })
    },
    OneclickFinish(i,DataGuid){
      let params = {
        DataGuid:DataGuid,
        Flag:1,
        Remarks:this.Remarks,
      };
      api.filemodifyData(params).then(res=>{
        if(res.data.Status==1){
           this.Catalogfilerejecteddialog=false
               this.$message({
                    message: '成功',
                    type: 'success'
                });
            if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
        }else{
          alert(res.data.Message)
        }
        console.log(res);
      })
    },
    Documentadoption(Id,DataGuid){//确定文件完成
      let params = {
        DataGuid:DataGuid,
        Flag:2,
      };
      api.filemodifyData(params).then(res=>{
         if(res.data.Status==1){
              this.$message({
                    message: '文件审核通过',
                    type: 'success'
              });
              if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
         }else{
           alert(res.data.Message)
         }
        console.log(res);
      })
    },
    //-------------------------------------------计划列表接口-------------------------------------------------------------------
    Addplan(){//打开创建计划弹框
       this.Newplandialog=true
       this.PlanName=""
       this.Remarks=""
       this.timelist=[]
    },
    OKCreateplan(){
      this.loadingfalg=true
    let params = {
        TopicFlowId: this.stageId,
        PlanName: this.PlanName,
        Remarks: this.Remarks,
        StartDate: this.timelist[0],
        EndDate: this.timelist[1],
      };
      api.establishplanData(params).then(res => {
        if (res.data.Status == 1) {
            this.loadingfalg=false
            this.Newplandialog=false
             if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
            this.$message({
                    message: '创建成功',
                    type: 'success'
                });
        }else{
          this.loadingfalg=false
          alert(res.data.Message)
        }
        console.log(res);
      });
    },
    Modifyplan(){//打开修改计划弹框
       this.Modifyplandialog=true
       this.Id=""
       this.PlanName=""
       this.Remarks=""
       this.timelist=[]
    },
    OKModifyplan(){//确定修改计划
      var params = {
        Id: this.Id,
        PlanName: this.PlanName,
        Remarks: this.Remarks,
        StartDate: this.timelist[0],
        EndDate: this.timelist[1],
      };
      api.reviseplanData(params).then(res => {
        if (res.data.Status == 1) {
            this.Modifyplandialog=false
              if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
            this.$message({
                    message: '修改成功',
                    type: 'success'
                });
        }else{
          alert(res.data.Message)
        }
        console.log(res);
      });
    },
    Deleteschedule(){//打开删除计划弹框
        this.Deletescheduledialog=true
        this.Id=""
    },
    OkDeleteschedule(){//确定删除计划
         let params={
              Id:this.Id
         }
          api.deleteplanData(params).then(res => {
            if (res.data.Status == 1) {
                 this.Deletescheduledialog=false
              if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
                 this.$message({
                    message: '删除成功',
                    type: 'success'
                 });
            }else{
              alert(res.data.Message)
            }
          });
    },
    Planfileupload(){//打开计划文件上传弹框
        this.Planfileuploaddialog=true
        this.Id=""
        this.Remarks=""
        this.planfileList = []
    },
    OKPlanupload(){//确定计划文件上传
      // this.$refs.plannewupload.submit();
            this.loadingfalg=true
      let params = {
        Id: this.Id,
        Remarks: this.Remarks,
        UserId: sessionStorage.getItem("Id"),
        Type:this.Type
      };
      let fd = new FormData();
      this.filearr.forEach(file => {
            fd.append('file',file.raw)
      })
      fd.append("data", JSON.stringify(params));
      api.planUploadData(fd).then(res => {
        if (res.data.Status == 1) {
          this.loadingfalg=false
            this.Planfileuploaddialog=false
            if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
            this.$message({
                message: '上传成功',
                type: 'success'
            });
        }else{
           this.loadingfalg=false
          alert(res.data.Message);
        }
        console.log(res);
      });
      return true;
    },
    Modifyplandescription(row){//打开计划修改文件说明弹框
      this.Modifyplandescriptiondialog=true
      this.Remarks= row.Remarks
      this.Id=""
      this.Id=row.Id
      this.editFolderPId = row.PDataGuid
      this.editType = row.Type
      this.editFileName = row.FileName + "." + row.Suffix
    },
    OKModifyplandescription(){//确定计划修改文件说明
      let params = {
        Id: this.Id,
        CheckFlag: 1,
        Remarks: this.Remarks,
        Type: this.editType,
        PDataGuid: this.editFolderPId
      };
      api.modificationData(params).then(res => {
        if (res.data.Status == 1) {
             this.Modifyplandescriptiondialog=false
              if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
             this.$message({
                message: '修改成功',
                type: 'success'
            });
        }else{
           alert(res.data.Message)
        }
      });
    },
    Deleteschedulefile(Id){//打开删除计划文件弹框
        this.Deleteschedulefiledialog=true
        this.Id=""
        this.Id=Id
    },
    OKDeleteschedule(){//确定删除计划修改文件
        let params = {
        Id: this.Id,
      };
       api.DeletecatalogfileData(params).then(res=>{
            if(res.data.Status==1){
              this.Deleteschedulefiledialog=false
              if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
                  this.$message({
                    message: '删除成功',
                    type: 'success'
                });
            }else{
               alert(res.data.Message)
            }
       })
    },
    plandownloadfile(Id){//计划文件下载
    console.log(Id);
       api.filepermissionsData(Id).then(res=>{
         console.log(res);
         if(res.status==200){
                let params={
                  Id:Id
                }
               api.filedownloadData(params).then(res=>{
                //  console.log(res,"22222222222");
                    let data = res.data;
                    let filename = decodeURIComponent(res.headers.filename);
                    this.downLoadXls(data, filename);
               })
         }else{
           alert(res.data.Message)
         }
       })
    },
    hangintheair(Id,item){//未完成
        let params = {
        Id: Id,
        Flag:0
        }
        api.CompletedData(params).then(res=>{
                console.log(res);
                if(res.data.Status==1){
               if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                });
                }
        })
        let obj = {
        DataGuid:item.DataGuid,
        Flag:-1,
        Remarks:"",
      };
      api.PlanapprovedData(obj).then(res=>{
         if(res.data.Status==1){
              // this.$message({
                console.log(11);
              //       message: '文件审核通过',
              //       type: 'success'
              // });
              // this.Stepslist()
         }else{
           alert(res.data.Message)
         }
        console.log(res);
      })
    },
    Completed(Id,item){//已完成
    console.log(Id,item);
        let params = {
        Id: Id,
        Flag:1
        }
        api.CompletedData(params).then(res=>{
                console.log(res);
                if(res.data.Status==1){
                    if(this.list.IsCheck==1){
                    this.Stepslist()
                  }else{
                    this.Stepslistphp()
                    this.fn(this.stageId)
                  }
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                });
                }
        })
       let obj = {
        DataGuid:item.DataGuid,
        Flag:2,
        Remarks:"",
      };
      api.PlanapprovedData(obj).then(res=>{
         if(res.data.Status==1){
           console.log(11);
              // this.$message({
              //       message: '文件审核通过',
              //       type: 'success'
              // });
              // this.Stepslist()
         }else{
           alert(res.data.Message)
         }
        console.log(res);
      })
    },
    Planrejection(Id,DataId){//文件驳回
       this.Plandocumentrejectiondialog=true
       this.ParentID=""
       this.Id=""
       this.Remarks=""
       this.ParentID=Id
       this.Id=DataId
    },
    OKPlandocumentrejection(){
      console.log(111);
      let params = {
        Id: this.ParentID,
        DataId:this.Id,
        Flag:-1,
        Remarks:this.Remarks,
      };
      api.PlanapprovedData(params).then(res=>{
        if(res.data.Status==1){
            if(this.list.IsCheck==1){
               this.Plandocumentrejectiondialog=false
               this.$message({
                    message: '文件审核驳回',
                    type: 'success'
                });
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
              this.Plandocumentrejectiondialog=false
              this.$message({
                    message: '文件审核驳回',
                    type: 'success'
                });
            }
        }else{
          alert(res.data.Message,"222")
        }
        console.log(res);
      })
    },
    Planpassed(Id,DataId){//文件通过
      let params = {
        Id: Id,
        DataId:DataId,
        Flag:2,
      };
      api.PlanapprovedData(params).then(res=>{
         if(res.data.Status==1){
              this.$message({
                    message: '文件审核通过',
                    type: 'success'
              });
             if(this.list.IsCheck==1){
              this.Stepslist()
            }else{
              this.Stepslistphp()
              this.fn(this.stageId)
            }
         }else{
           alert(res.data.Message)
         }
        console.log(res);
      })
    },
    //-------------------------------------------审核接口-------------------------------------------------------------------
    Submitforreview(){//提交审核
    let params={
       Id:this.stageId
    }
      api.SubmitforreviewData(params).then(res=>{
              console.log(res);
              if(res.data.Status==1){
                  this.Stepslist()
                  this.singletopiclist()
                  this.$message({
                    message: '提交成功',
                    type: 'success'
                });
                this.allApproveddialog=false
              }else{
                alert(res.data.Message)
              }
      })
    },
    Approved(){//打开审核通过弹框
    this.allApproveddialog=true
    },
    OKallApproved(){//确定审核通过
        let params = {
        Id: this.stageId,
        Flag: 3,
        UserId: sessionStorage.getItem("Id"),
      };
      api.ReviewrejectedData(params).then(res => {
        if (res.data.Status == 1) {
               this.Stepslist()
               this.singletopiclist()
               this.$message({
                   message: '审核通过',
                  type: 'success'
                });
              this.allApproveddialog=false
        }else{
          alert(res.data.Message);
        }
        console.log(res);
      });
    },
    Stagerejection(){//打开审核驳回弹框
      this.Stageapprovalrejectiondialog=true
      this.Remarks=""
    },
    OKStagerejection(){//确定审核驳回
      let params = {
        Id: this.stageId,
        Flag: -1,
        UserId: sessionStorage.getItem("Id"),
        Remarks:this.Remarks
      };
      api.ReviewrejectedData(params).then(res => {
        if (res.data.Status == 1) {
          this.Stageapprovalrejectiondialog=false
               this.Stepslist()
               this.singletopiclist()
               this.$message({
                   message: '审核驳回',
                  type: 'success'
                });
        }else{
          alert(res.data.Message);
        }
        console.log(res);
      });
    },
    Coursenotlocked(num){//打开课程锁定弹框
    this.lockingdialog=true
    console.log(num,"@@@@@@");
    if(num==0){
      this.i="锁定"
    }else{
      this.i="解锁"
    }
    },
    OKlocking(){
      let params = {
        Id: this.projectId,
      }
        api.lockingData(params).then(res=>{
           console.log(res);
           if(res.data.Status==1){
             this.lockingdialog=false
             if(this.list.LockFlag==0){
                  this.$message({
                    message: '课题锁定成功',
                    type: 'success'
                  });
             }else{
                  this.$message({
                    message: '课题解锁成功',
                    type: 'success'
                  });
             }
             this.singletopiclist()

           }else{
             alert(res.data.Message)
           }
        })
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";
.ScientificResearchProcess {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .ScientificResearchProcess_left {
    width: 260px;
    height: 94%;
    float: left;
    border-radius: 10px;
    background: #fff;
    margin-top: 2%;
    margin-right: 20px;
  }
  .ScientificResearchProcess_right {
    width: 82%;
    //width: 1342px;
    height: 95%;
    float: left;
    margin-top: 2%;
    border-radius: 10px;
    transition: width 1s;
    .ScientificResearchProcess_right_left {
      width: 81%;
      height: 100%;
      float: left;
      transition: width 1s;
      .ScientificResearchProcess_right_left_top {
        //初始高度
        width: 100%;
        height: 34%;
        // background: darkmagenta;
        margin-bottom: 1.5%;
        transition: height 1s;
        background: #fff;
        border-radius: 10px;
        .scientific_text {
          letter-spacing: 2px;
          @include add-size($font_size_16);
          color: #333;
        }
        h2 {
          height: 40px;
          // background: darkgreen;
          p:nth-child(1) {
            float: left;
            span:nth-child(1) {
              display: inline-block;
              width: 3px;
              height: 18px;
              background: #3388ff;
              margin-right: 10px;
              margin-left: 10px;
            }
            span:nth-child(2) {
              display: inline-block;
              @include add-size1($font_size_20);
            }
          }
          p:nth-child(2) {
            float: right;
            span:nth-child(1) {
              display: inline-block;
              @include add-size($font_size_16);
              margin-right: 10px;
              color: #333333;
              a {
                @include add-size($font_size_16);
                color: red;
              }
            }
          }
        }
        .ScientificResearchProcess_div {
          width: 100%;
          height: 234px;
          .ScientificResearchProcess_div_left {
            width: 65%;
            float: left;
            height: 234px;
            .ScientificResearchProcess_div_top {
              width: 90%;
              height: 110px;
              background: #f0f7fd;
              border-radius: 10px;
              margin-left: 20px;
              overflow: hidden;
              margin-bottom: 20px;
              p {
                margin-top: 6px;
              }
              p:nth-child(1) {
                img {
                  width: 20px;
                  height: 20px;
                  vertical-align: middle;
                  margin-right: 6px;
                  margin-left: 20px;
                }
                span:nth-child(2) {
                  // width: 80%;
                  display: inline-block;
                  vertical-align: middle;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  @include add-size4($font_size_18);
                  color: #585858;
                  font-weight: 500;
                }
                span:nth-child(3) {
                  text-align: center;
                  width: 70px;
                  line-height: 24px;
                  display: inline-block;
                  vertical-align: middle;
                  background: #ff9900;
                  color: #fff;
                  border-radius: 4px;
                  @include add-size5($font_size_14);
                  margin-left: 10px;
                }
              }
              p:nth-child(2) {
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 6px;
                  vertical-align: middle;
                  margin-left: 20px;
                }
                span:nth-child(2) {
                  vertical-align: middle;
                  @include add-size4($font_size_16);
                  margin-right: 20px;
                  color: #585858;
                }
                span:nth-child(4) {
                  width: 50%;
                  display: inline-block;
                  vertical-align: middle;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: #585858;
                  @include add-size4($font_size_16);
                }
              }
              p:nth-child(3) {
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 6px;
                  vertical-align: middle;
                  margin-left: 20px;
                }
                span:nth-child(2) {
                  vertical-align: middle;
                  @include add-size4($font_size_16);
                   color: #585858;
                }
              }
            }
            .ScientificResearchProcess_div_bottom {
              width: 100%;
              height: 104px;
              overflow: hidden;
              .div1{
                margin-top: 10px;
                color: #000;
                // text-align: left;
                // width: 100px;
                font-size: 16px;
              }
               .div2{
                color:#319efd;
              }
              /deep/.el-step.is-center .el-step__description{
                    padding-left: 10%;
                    padding-right:5%
              }
              /deep/.el-step__icon {
                width: 48px;
                height: 48px;
              }
              /deep/.el-step.is-horizontal .el-step__line {
                top: 21px;
              }
              /deep/.el-step__icon-inner {
                font-size: 20px;
              }
              // /deep/.el-step__title.is-success {
              //   color: #319efd;
              // }
              /deep/.el-step__head.is-success {
                color: #319efd;
                border-color: #319efd;
              }
              //   /deep/.el-step__head.is-success {
              //     color: #efa73b;
              //     border-color: #efa73b;
              //   }
              //   /deep/.el-step__title.is-success {
              //   color: #efa73b;
              // }
            }
          }
          .ScientificResearchProcess_div_right {
            //width: 35%;
            width: 370px;
            float: left;
            height: 234px;
            .mainC {
            margin: 0 auto;
            width: 100%;
            height: 208px;
            float: left;
            text-align: center;
            overflow: hidden;
            position: relative;
            /deep/.el-progress {
              margin-top: 10px;
            }
            /deep/.el-progress__text {
              font-size: 34px !important;
              color: #31acfa;
              top: 40%;
            }
            p {
              font-size: 16px;
              position: absolute;
              top: 110px;
              left: 158px;
              color: #333333;
            }
          }
          }
        }
      }
      .ScientificResearchProcess_right_left_top1 {
        height: 5%;
      }
      .ScientificResearchProcess_right_left_bottom {
        //初始高度
        width: 100%;
        height: 64%;
        background: #fff;
        border-radius: 10px;
        transition: height 1s;
        .ScientificResearchProcess_head {
          width: 100%;
          height: 10%;
          // background: red;
          overflow: hidden;
          button {
            margin-left: 20px;
            width: 112px;
            height: 32px;
            background: #409EFF;
            border: 0;
            border-radius: 4px;
            color: #fff;
            margin-top:10px;
             @include add-size($font_size_16);
          }
          span{
            margin-left: 10px;
            @include add-size($font_size_16);
             overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #666;
          }
        }
        .ScientificResearchProcess_box {
          width: 96%;
          height: 80%;
          //  background: chocolate;
          margin: 0 auto;
          .ScientificResearchProcess_box_left {
            width: 49%;
            float: left;
            height: 100%;
            margin-right: 2%;
            border: 1px solid #e9e9e9;
            box-sizing: border-box;
            h2 {
              width: 100%;
              height: 40px;
              background: #fbfbfd;
              border-bottom: 1px solid #e9e9e9;
              p:nth-child(1) {
                float: left;
                img {
                  width: 20px;
                  height: 20px;
                  margin-left: 9px;
                  margin-right: 4px;
                  vertical-align: middle;
                }
                span {
                  vertical-align: middle;
                  @include add-size($font_size_16);
                  color: #333333;
                }
              }
              p:nth-child(2) {
                float: right;
                img {
                  width: 20px;
                  height: 20px;
                  vertical-align: middle;
                  margin-right: 10px;
                  cursor: pointer;
                }
              }
            }
            .ScientificResearchProcess_mulu {
              margin: 0 auto;
              width: 94%;
              height: 90%;
              overflow: hidden;
              //   background: cadetblue;
              overflow:auto;
              .ScientificResearchProcess_mulu_1 {
                margin-top: 16px;
                border-bottom: 1px solid #e9e9e9;
                h3 {
                  margin-bottom: 10px;
                  font-weight: 400;
                  color: #303133;
                  .span3 {
                    width: 80%;
                     display: inline-block;
                    @include add-size($font_size_16);
                    vertical-align: middle;
                  }
                  img{
                    // margin-left: 6px;
                    vertical-align: middle;
                  }
                  .spana{
                    text-align: center;
                    width: 64px;
                     height: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    border: 1px solid #0b77e6;
                    color: #666666;
                    border-radius: 4px;
                    font-weight: 400;
                    @include add-size5($font_size_14);
                    margin-right: 20px;
                  }
                  .span1{
                    text-align: center;
                    width: 64px;
                    display: inline-block;
                    vertical-align: middle;
                    @include add-size5($font_size_14);
                    border: 1px solid #0b77e6;
                    color: #666666;
                    border-radius: 4px;
                    font-weight: 400;
                  }
                  .span2{
                    display: inline-block;
                    width: 80px;
                    height: 20px;
                    vertical-align: middle;
                    border: 1px solid #0b77e6;
                    color: #666666;
                    border-radius: 4px;
                    font-weight: 400;
                  }
                  .el-dropdown{
                    margin-bottom: 10px;
                    @include add-size5($font_size_14);
                  }
                }
                h4 {
                  font-weight: 400;
                  margin-bottom: 6px;
                  img {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                    margin-right: 6px;
                  }
                  span:nth-child(2) {
                    vertical-align: middle;
                    @include add-size($font_size_16);
                    color: #666666;
                  }
                }
                h5 {
                  margin-bottom: 6px;
                  overflow: hidden;
                  img {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                    margin-right: 6px;
                  }
                  span:nth-child(2) {
                    width: 75%;
                    display: inline-block;
                    vertical-align: middle;
                    @include add-size($font_size_16);
                    color: #666666;
                    font-weight: 400;
                  }
                  span:nth-child(3) {
                    display: inline-block;
                    vertical-align: middle;
                    @include add-size5($font_size_14);
                    border: 1px solid #0b77e6;
                    color: #666666;
                    border-radius: 4px;
                    font-weight: 400;
                    margin-left: 20px;
                    padding-left: 10px;
                    padding-right: 10px;
                  }
                  span:nth-child(4){
                    width: 80px;
                    display: inline-block;
                    @include add-size5($font_size_14);
                    vertical-align: middle;
                    border: 1px solid #3388FF ;
                    border-radius: 4px;
                    font-weight: 400;
                  }
                  p {
                    @include add-size($font_size_16);
                    margin-left: 26px;
                    color: #666666;
                    margin-top: 10px;
                    font-weight: 400;
                  }
                  .el-dropdown{
                    @include add-size5($font_size_14);
                  }
                }
              }
            }
          }
          .ScientificResearchProcess_box_right {
            width: 49%;
            float: left;
            height: 100%;
            border: 1px solid #e9e9e9;
            box-sizing: border-box;
            overflow: hidden;
            overflow:auto;
            h2 {
              width: 100%;
               height: 40px;
              background: #fbfbfd;
              border-bottom: 1px solid #e9e9e9;
              // margin-bottom: 20px;
              p:nth-child(1) {
                float: left;
                img {
                  width: 20px;
                  height: 20px;
                  margin-left: 9px;
                  vertical-align: middle;
                }
                span {
                  vertical-align: middle;
                  @include add-size($font_size_16);
                  color: #333333;
                  margin-left: 4px;
                }
              }
              p:nth-child(2) {
                float: right;
                img {
                  width: 20px;
                  height: 20px;
                  vertical-align: middle;
                  margin-right: 10px;
                  cursor: pointer;
                }
              }
            }
            .ScientificResearchProcess_box_jihua{
                 width: 92%;
                 height: 360px;
                  margin: 0 auto;
                  overflow:auto;
                  .ScientificResearchProcess_box_mm{
                    margin-top: 26px;
                  }
                .timeline_div{
                   border: 1px solid #E9E9E9;
                   overflow: hidden;
                   .timeline_h6{
                     width: 100%;
                     margin-top: 8px;
                     margin-bottom: 4px;
                     .span11{
                        width:78%;
                        @include add-size($font_size_16);
                        display: inline-block;
                        margin-left: 12px;
                     }
                     .span22{
                           display: inline-block;
                           @include add-size5($font_size_14);
                           border: 1px solid #3388FF ;
                           padding-left: 10px;
                           padding-right: 10px;
                           border-radius: 4px;
                     }
                     .span33{
                          width: 80px;
                          //  background: red;
                           display: inline-block;
                          //  @include add-size5($font_size_14);
                           border: 1px solid #3388FF ;
                          //  font-weight: 500;
                          // float: right;
                          .span1{
                            @include add-size5($font_size_14);
                          }
                     }
                      // span:nth-child(1){
                      //   @include add-size($font_size_16);
                      //      color: #55657a;
                      //      margin-left: 12px;
                      //      display: inline-block;
                      //      background: darkcyan;
                      // }
                      // span:nth-child(2){
                      //      display: inline-block;
                      //      @include add-size($font_size_16);
                      //      border: 1px solid #3388FF ;
                      // }
                      // span:nth-child(3){
                      //      width: 80px;
                      //     //  background: red;
                      //      display: inline-block;
                      //     //  @include add-size5($font_size_14);
                      //      border: 1px solid #3388FF ;
                      //     //  font-weight: 500;
                      //     // float: right;
                      //     .span1{
                      //       @include add-size5($font_size_14);
                      //     }
                      // }
                   }
                   .timeline_h3{
                      margin-bottom: 4px;
                      color: #666666;
                      img{
                           width: 26px;
                           height: 26px;
                           vertical-align: middle;
                           margin-left: 10px;
                           margin-right: 5px;
                      }
                      span:nth-child(2){
                           @include add-size($font_size_16);
                           vertical-align: middle;
                      }
                   }
                   .timeline_h4{
                     color: #666666;
                     overflow: hidden;
                      img{
                           width: 20px;
                           height: 20px;
                           vertical-align: middle;
                           margin-left: 14px;
                           margin-right: 6px;
                      }
                      span:nth-child(2){
                           display: inline-block;
                           width: 70%;
                           @include add-size($font_size_16);
                           vertical-align: middle;
                      }
                      span:nth-child(3){
                           display: inline-block;
                           @include add-size5($font_size_14);
                           vertical-align: middle;
                           border: 1px solid #3388FF ;
                           border-radius: 4px;
                           margin-left: 8px;
                           padding-left: 10px;
                           padding-right: 10px;
                      }
                     span:nth-child(4){
                           width: 80px;
                           display: inline-block;
                           @include add-size($font_size_16);
                           vertical-align: middle;
                           border: 1px solid #3388FF ;
                           border-radius: 4px;
                      }
                      p{
                         margin-top:8px;
                         margin-left: 40px;
                         @include add-size($font_size_16);
                      }
                        .el-dropdown{
                       @include add-size5($font_size_14);
                     }
                   }
                }
            }
          }
        }
        .ScientificResearchProcess_box_center {
          width: 100%;
          height: 10%;
          display: flex;
          justify-content: center;
          align-items:center;
        }
      }
      .ScientificResearchProcess_right_left_bottom1 {
        height: 93%;
      }
    }
    .ScientificResearchProcess_right_right {
      width: 250px;
      height: 100%;
      float: right;
      background: #fff;
      border-radius: 10px;
      transition: width 1s;
      overflow: hidden;
      h2{
        height: 5%;
        // background: blue;
        span:nth-child(1){
              display: inline-block;
              width: 3px;
              height: 18px;
              background: #3388ff;
              margin-right: 10px;
              margin-left: 10px;
        }
        span:nth-child(2){
          @include add-size1($font_size_20);
        }
      }
      .ScientificResearchProcess_right_p{
         width: 250px;
         height: 95%;
          .div2{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 10px;
            span{
              display: inline-block;
              margin-bottom: 16px;
              margin-left: 10px;
              @include add-size($font_size_16);
              color: #585858;
            }
          }
      }
    }
    .ScientificResearchProcess_right_right1 {
      width: 0!important;
    }
    .ScientificResearchProcess_right_left1 {
      width: 83%;
    }
   .ScientificResearchProcess_right_left2 {
      width: 100%;
    }
   .ScientificResearchProcess_right_left3 {
      width: 99%;
    }
  }
  .ScientificResearchProcess_right1 {
    width: 84%;
  }
//   弹框-------------------------------------------------------------------------------------------------------------------------
  .addcataloguedialog{
          /deep/.el-dialog {
      width: 600px;
      border-radius: 10px;
      .el-dialog__header {
        border-radius: 10px 10px 0px 0px;
        background: #3388ff;
        .header-title {
          .title-name {
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 4px solid #ffffff;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 4px;
            margin-bottom: 5px;
          }
          .title-age {
            vertical-align: top;
            display: inline-block;
            @include add-size($font_size_16);
            color: #ffffff;
          }
        }
        .el-dialog__headerbtn .el-dialog__close {
          color: #fff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
      .body {
        // text-align: center;
        .el-form {
            .el-form-item {
                .el-form-item__label {
                    @include add-size($font_size_16);
                    color: #666666;
                }
                .el-input {
                    @include add-size($font_size_16);
                    color: #666666;
                    width: 460px;
                }
                .el-textarea{
                    color: #666666;
                     @include add-size($font_size_16);
                }
                .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
                   width: 460px;
                }
            }
        }
        p{
         text-align: center;
         margin-bottom: 30px;
        .span1{
        display: inline-block;
        width: 26px;
        line-height: 26px;
        border-radius: 50%;
        background: #ffba00;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        vertical-align: middle;
      }
      .span2{
        display: inline-block;
        vertical-align: middle;
        color: #666666;
        @include add-size1($font_size_18);
      }
        }
      }
      .btn {
        text-align: center;
        button:nth-child(1) {
          width: 180px;
          background: #ffffff;
          border: 1px solid #3388ff;
          height: 50px;
          border-radius: 10px;
          font-size: 24px;
          color: #3388ff;
          margin-right: 20px;
        }
        button:nth-child(2) {
          width: 180px;
          background: #3388ff;
          border: 1px solid #3388ff;
          height: 50px;
          border-radius: 10px;
          font-size: 24px;
          color: #ffffff;
          margin-right: 20px;
        }
      }
    }
  }
}
/deep/ .el-icon-user-solid:before {
  color: #B9C7DB;
}
::-webkit-scrollbar {
width: 10px; /*对垂直流动条有效*/
height: 10px; /*对水平流动条有效*/
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track{
background-color: #fff;
border-radius: 3px;
}


/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
border-radius: 7px;
background-color: #1677FF;
}
</style>
